/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import ModalContactForm from "../components/modal-contact-form/modal-contact-form"
import { Button } from "../components/button/button"

export default function Pricing() {
  return (
    <Layout>
      <Metadata
        title="Pricing"
        // description="We hire the best. We emphasize collaboration and transparent
        // communication to improve creativity and effectiveness. The team is
        // supportive, engaging, interactive, and of course fascinated by
        // virtual reality."
        //image={buildSocialImage(socialImage)}
      />
      <Panel
        sx={{
          pt: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumn: "1 / -1",
            pt: ["3rem", "unset"],
            textAlign: "center",
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{
              "--max-font-size": [2.6, 3.8, 5.8],
              mb: ["2.8rem", "6rem", "8rem"],
            }}
          >
            Pricing designed for Enterprise
          </Themed.h1>
          <ModalContactForm
            trigger={<Button>Connect with us to learn more</Button>}
          />
        </div>
      </Panel>
      <Panel
        sx={{
          py: ["4rem", "6rem", "8rem"],
          gap: ["3rem", "4rem"],
          "> div": {
            gridColumnStart: [1, "unset"],
            gridColumnEnd: ["-1", "span 4"],
            bg: "bgGray",
            p: ["2.4rem", "3.4rem"],
            borderRadius: "1.6rem",
            // fontSize: "1.4rem",
            lineHeight: 1.5,
            "--min-font-size": [1.4, null, 1.6],
            "--max-font-size": [1.4, null, 2],
          },
          h2: {
            "--min-font-size": [1.8, null, 2],
            "--max-font-size": [1.8, null, 3],
            lineHeight: 1.5,
            textAlign: "center",
          },
          ".price": {
            color: "blue",
            textTransform: "uppercase",
            fontWeight: 600,
            textAlign: "center",
            mb: 0,
          },
          ".desc": {
            position: "relative",
            textAlign: "center",
            py: ["2rem", null, "3rem"],
            my: ["2rem", null, "3rem"],
            "&:before, &:after": {
              content: "''",
              height: "3px",
              width: "86px",
              bg: "#d5d7d8",
              display: "block",
              position: "absolute",
              left: "calc(50% - 43px)",
            },
            "&:before": {
              top: 0,
            },
            "&:after": {
              bottom: 0,
            },
          },
          ul: {
            pl: "1.5rem",
            mt: 0,
            mb: ["2.6rem", "3.2rem", "4rem"],
            listStyleType: "square",
            li: {
              mb: "1.8rem",
              "&::marker": {
                color: "#d5d7d8",
                fontSize: ["2rem", null, "3rem"],
                lineHeight: 0,
              },
            },
          },
        }}
      >
        <div className="scaleText">
          <Themed.h2>Basic Plan</Themed.h2>
          <Themed.p className="price">$99 per learner per year</Themed.p>
          <Themed.p className="desc">
            Access our basic library and help your employees improve their communication skills.
          </Themed.p>
          <Themed.ul>
            <li>Emotional Intelligence Essentials library</li>
            <li>VR and iOS compatibility</li>
            <li>Aggregated/anonymous analytics</li>
          </Themed.ul>
        </div>
        <div className="scaleText">
          <Themed.h2>Premium Plan</Themed.h2>
          <Themed.p className="price">$199 per learner per year</Themed.p>
          <Themed.p className="desc">
            Access a premium library and track detailed usage.
          </Themed.p>
          <Themed.p sx={{ mb: "2rem !important", fontStyle: "italic" }}>
            Everything from Basic Plan plus:
          </Themed.p>
          <Themed.ul>
            <li>Individual-level learner analytics</li>
            <li>Annual ROI case study</li>
            <li>
              One premium library: Suicide Intervention, National Urban League, Society for Human Resource Management, Sexual Assault Intervention, Change Management, Language Immersion Training, Organizational Integrity, Psychological Safety, Art of Persuasion, Mental Wellness, and others.
            </li>
          </Themed.ul>
        </div>
        <div className="scaleText">
          <Themed.h2>Enterprise Plan</Themed.h2>
          <Themed.p className="price">Contact us for pricing</Themed.p>
          <Themed.p className="desc">
            Develop customized immersive experiences for your team using our platform, resources and expertise.
          </Themed.p>
          <Themed.p sx={{ mb: "2rem !important", fontStyle: "italic" }}>
            Everything from Premium Plan plus:
          </Themed.p>
          <Themed.ul>
            <li>Scaled Learner user base</li>
            <li>Data integration</li>
            <li>Additional Premium Libraries</li>
            <li>Access to custom content authoring tools</li>
          </Themed.ul>
        </div>
      </Panel>
    </Layout>
  )
}
